import styled from "styled-components";
import BT from "../../assets/BannerTutela.png";
import AUI from "../../assets/sobrenoshome.png";
import HH from "../../assets/HH.png";
import AH from "../../assets/AH.png";

export const Container = styled.div`
width: 100%;
height: 100%;
`;

export const BannerContainer = styled.div`
background-image: url(${BT});
background-repeat: no-repeat;
background-position: center;
background-size: cover;
width: 100%;
height: 45vw;
`;


export const AUContainer = styled.div`
height: 50vh;
width: 100%;
display: flex;
flex-direction: row;
background: rgb(244,98,222);
background: linear-gradient(180deg, rgba(244,98,222,1) 16%, rgba(102,175,249,1) 51%, rgba(150,198,246,1) 100%);
color: white;
font-family: 'Anton', sans-serif;

@media (max-width:768px) {
flex-direction: column;
height: auto;
}
`;

export const AUTextContainer = styled.div`
width: 50%;
height: 100%;

@media (max-width:768px) {
width: 100%;
height: auto;
padding-top: 4vw;
}
`;

export const AUTextTittle = styled.div`
width: 100%;
height: 25%;
padding-left: 4vw;
display: flex;
align-items: center;
font-size: 2vw;
font-weight: 500;

@media (max-width:768px) {
width: 90%;
margin: 0 auto;
font-size: 4vw;
padding-left: 0;
}
`;


export const AUTextContent = styled.div`
width: 100%;
height: 60%;
display: flex;
justify-content: start;
margin-left: 50px;
flex-direction: column;

@media (max-width:768px) {
width: 90%;
margin: 0 auto;
}
`;

export const AUText = styled.div`
width: 80%;
height: auto;
font-size: 1.5vw;
text-align: justify;
text-overflow: ellipsis;

@media (max-width:768px) {
font-size: 3vw;
width: 100%;
}
`;

export const Nm = styled.div`
margin-left: 1vw;
font-size: 1.5vw;
@media (max-width:768px) {
font-size: 3vw;
}
`;

export const TextLink = styled.div`
height: 1.563vw;
font-size: 1.5vw;
margin-top: 1vw;
display: flex;
justify-content: left;
align-items: center;
cursor: pointer;
transition: all .2s ease-in-out;

&:hover {
    transform: scale(0.9);
}
`;

export const AUImgContainer = styled.div`
width: 50%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;

@media (max-width:768px) {
width: 100%;
height: 50vw;
}
`;

export const AUImgContent = styled.div`
width: 80%;
height: 80%;
border-radius: 20px;
background-image: url(${AUI});
background-repeat: no-repeat;
background-position: center;
background-size: cover;
box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
`;

export const HContainer = styled.div`
height: 50vh;
width: 100%;
display: flex;
flex-direction: row;
background: rgb(244,98,222);
background: linear-gradient(0deg, rgba(244,98,222,1) 16%, rgba(102,175,249,1) 51%, rgba(150,198,246,1) 100%);
color: white;
font-family: 'Anton', sans-serif;

@media (max-width:768px) {
flex-direction: column-reverse;
height: auto;
}
`;

export const HImgContainer =styled.div`
width: 50%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;

@media (max-width:768px) {
width: 100%;
height: 50vw;
}
`;

export const HImgContent = styled.div`
width: 80%;
height: 90%;
border-radius: 20px;
box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
background-image: url(${HH});
background-repeat: no-repeat;
background-position: center;
background-size: cover;
`;

export const HTextContainer = styled.div`
width: 50%;
height: 100%;

@media (max-width:768px) {
width: 100%;
height: auto;
padding-top: 4vw;
}
`;

export const HTextTittle = styled.div`
width: 90%;
height: 25%;
display: flex;
align-items: center;
justify-content: end;
font-size: 2vw;
font-weight: 500;

@media (max-width:768px) {
width: 90%;
margin: 0 auto;
font-size: 4vw;
padding-left: 0;
justify-content: start;
}
`;

export const HTextContent = styled.div`
width: 90%;
height: auto;
display: flex;
justify-content: start;
flex-direction: column;
margin-left: 2vw;

@media (max-width:768px) {
width: 90%;
margin: 0 auto;
}
`;

export const HText = styled.div`
width: 100%;
height: 100%;
font-size: 1.5vw;
text-align: justify;
text-overflow: ellipsis;

@media (max-width:768px) {
font-size: 3vw;
width: 100%;
}
`;

export const EContainer = styled.div`
height: 50vh;
width: 100%;
display: flex;
flex-direction: row;
background: rgb(244,98,222);
background: linear-gradient(180deg, rgba(244,98,222,1) 16%, rgba(102,175,249,1) 51%, rgba(255,255,255,1) 100%);
color: white;
font-family: 'Anton', sans-serif;

@media (max-width:768px) {
flex-direction: column;
height: auto;
}
`;

export const ETextContainer = styled.div`
width: 50%;
height: 100%;

@media (max-width:768px) {
width: 100%;
height: auto;
padding-top: 4vw;
}
`;

export const ETextTittle = styled.div`
width: 100%;
height: 25%;
padding-left: 4vw;
display: flex;
align-items: center;
font-size: 2vw;
font-weight: 500;

@media (max-width:768px) {
width: 90%;
margin: 0 auto;
font-size: 4vw;
padding-left: 0;
}
`;

export const ETextContent = styled.div`
width: 100%;
height: 60%;
display: flex;
justify-content: start;
margin-left: 50px;
flex-direction: column;

@media (max-width:768px) {
width: 90%;
margin: 0 auto;
}
`;

export const EText = styled.div`
width: 80%;
height: auto;
font-size: 1.5vw;
text-align: justify;
text-overflow: ellipsis;

@media (max-width:768px) {
font-size: 3vw;
width: 100%;
}
`;

export const EImgContainer = styled.div`
width: 50%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;

@media (max-width:768px) {
width: 100%;
height: 50vw;
}
`;

export const EImgContent = styled.div`
width: 80%;
height: 80%;
border-radius: 20px;
box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
background-image: url(${AH});
background-repeat: no-repeat;
background-position: center;
background-size: cover;
`;