import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Home from "./pages/home";
import DDM from "./components/menu";
import Team from "./pages/Time";
import Lineup from "./pages/escalação";
import History from "./pages/historia";
import Sponsors from "./pages/patrocinadores";
import Footer from "./components/footer";


function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <div style={{ width:"100%", display:"flex"}}>
          <DDM />
          <div style={{width:"100%"}}>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/Time" element={<Team/>}/>
              <Route path="/Jogadoras" element={<Lineup/>}/>
              <Route path="/Historia" element={<History/>}/>
              <Route path="/Patrocinadores" element={<Sponsors/>}/>
            </Routes>
          </div>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
