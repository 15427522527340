import { useNavigate } from "react-router-dom";
import { AUContainer, AUImgContainer, AUImgContent, AUText, AUTextContainer, AUTextContent, AUTextTittle, BannerContainer, Container, EContainer, EImgContainer, EImgContent, EText, ETextContainer, ETextContent, ETextTittle, HContainer, HImgContainer, HImgContent, HText, HTextContainer, HTextContent, HTextTittle, Nm, TextLink } from "./styles";
import { BsArrowRightCircleFill } from "react-icons/bs"
import { useEffect } from "react";

function Home (){ 
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [navigate]);

    return(
        <Container >
            <BannerContainer>
            </BannerContainer>
            <AUContainer>
                <AUTextContainer>
                    <AUTextTittle>SOBRE NÓS</AUTextTittle>
                    <AUTextContent>
                        <AUText>O TUTELA FUTEBOL CLUBE é um time feminino focado em vencer, vencer e vencer! sempre almejando o pódio com garra e determinação das nossas jogadoras.</AUText>
                        <TextLink onClick={()=> navigate("/Time")}><BsArrowRightCircleFill style={{fontSize:"3vw"}}/><Nm>Saiba Mais</Nm> </TextLink>                                            
                    </AUTextContent> 
                </AUTextContainer>
                <AUImgContainer>
                    <AUImgContent/>
                </AUImgContainer>
            </AUContainer>
            <HContainer>
                <HImgContainer>
                    <HImgContent/>
                </HImgContainer>
                <HTextContainer>
                    <HTextTittle> TÍTULOS </HTextTittle>
                    <HTextContent>
                        <HText>O tutela conta com uma trajetória verdadeiramente vitoriosa, dominando os campeonatos por onde passa!</HText>
                        <TextLink onClick={()=> navigate("/Historia")}><BsArrowRightCircleFill style={{fontSize:"3vw"}}/> <Nm>Saiba Mais</Nm></TextLink>     
                    </HTextContent>
                </HTextContainer>
            </HContainer>
            <EContainer>
                <ETextContainer>
                    <ETextTittle>ATLETAS</ETextTittle>
                    <ETextContent>
                        <EText>O que seria de um time campeão sem o seu elenco de vencedoras, não? conheça mais sobre nossa seleção.</EText>
                        <TextLink onClick={()=> navigate("/Jogadoras")}><BsArrowRightCircleFill style={{fontSize:"3vw"}}/><Nm>Saiba Mais</Nm></TextLink>     
                    </ETextContent>
                </ETextContainer>
                <EImgContainer>
                    <EImgContent/>
                </EImgContainer>
            </EContainer>
        </Container>
    )
}

export default Home;