import styled from "styled-components";

export const Container = styled.header`
width:100%;
min-height: 60px;
height: 6.5vw;
background-color: white;
padding: 0 0;
box-sizing: border-box;
display: flex;
align-items: center;
justify-content: space-between;
top: 0;
font-family: 'Anton', sans-serif;
`;

export const LContainer = styled.div`
height: 100%;
width: 50%;
display: flex;
flex-direction: row;
margin: 0 auto;

@media (max-width:768px) {
margin-left: 4vw;
}
`;

export const Logo = styled.img`
height: 90%;
object-fit: contain;
font-size: 90px;
`;

export const LogoContainer = styled.div`
width: 100%;
height: 100%;
display: flex;
align-items: center;
`;

export const RContainer = styled.div`
width: auto;
height: 100%;
display: flex;
flex-direction: row;
align-items: center;
padding-right: 30px;
`;

export const NavigateButton1 = styled.div`
width: auto;
height: 80%;
background-color: white;
display: flex;
justify-content: center;
align-items: center;
margin-left: 1vw;
cursor: pointer;
color: rgba(244,98,222,1);
border-radius:10px ;
box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
transition: all .2s ease-in-out;

@media (max-width:768px) {
display: none;
}

&:hover {
background-color: rgba(244,98,222,1);
color: white;
transform: scale(1.2);

}
`;

export const NavigateButton2 = styled.div`
width: auto;
height: 80%;
background-color: white;
display: flex;
justify-content: center;
align-items: center;
margin-left: 1vw;
cursor: pointer;
color: rgba(102,175,249,1);
box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
border-radius:10px ;
transition: all .2s ease-in-out;

@media (max-width:768px) {
display: none;
}

&:hover {
background-color: rgba(102,175,249,1);
color: white;
transform: scale(1.2);

}
`;

export const NavigateButton3 = styled.div`
width: auto;
height: 80%;
background-color: white;
display: flex;
justify-content: center;
align-items: center;
margin-left: 1vw;
cursor: pointer;
color: rgba(50,205,50,1);
box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
border-radius:10px ;
transition: all .2s ease-in-out;

@media (max-width:768px) {
display: none;
}

&:hover {
background-color: rgba(50,205,50,1);
color: white;
transform: scale(1.2);

}
`;

export const NavigateButton4 = styled.div`
width: auto;
height: 80%;
background-color: white;
display: flex;
justify-content: center;
align-items: center;
margin-left: 1vw;
cursor: pointer;
color: rgba(239, 184, 16,1);
box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
border-radius:10px ;
transition: all .2s ease-in-out;

@media (max-width:768px) {
display: none;
}

&:hover {
background-color: rgba(239, 184, 16,1);
color: white;
transform: scale(1.2);

}
`;

export const NT = styled.span`
font-size: 1.5vw;
margin: 2vw;
`;

export const DDM = styled.button`
display: none;
justify-content: center;
align-items: center;
width: 100%;
position: relative;
border: none;
background: transparent;
padding: 0;
cursor: pointer;
outline-offset: 4px;
transition: filter 250ms;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;

&:hover {
filter: brightness(110%);
-webkit-filter: brightness(110%);
transition:
transform
250ms
cubic-bezier(.3, .7, .4, 1.5);
}

:active{
transform: translateY(-2px);
transition: transform 60ms;
}

@media (max-width:768px) {
display: flex;
}
`;

export const Span1DDM = styled.span`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
border-radius: 12px;
background: hsl(0deg 0% 0% / 0.25);
will-change: transform;
transform: translateY(2px);
transition:
transform
600ms
cubic-bezier(.3, .7, .4, 1);
`;

export const Span2DDM = styled.span`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
border-radius: 12px;
background: linear-gradient(
to left,
hsl(308.426 100% 16%) 0%,
hsl(308.426 100% 32%) 8%,
hsl(308.426 100% 32%) 90%,
hsl(308.426 100% 16%) 100%
  );
`;

export const Span3DDM = styled.span`
display: block;
position: relative;
padding: 3px 15px;
border-radius: 12px;
font-size: 2rem;
color: white;
background: hsl(308.426, 100%, 54%, 1);
will-change: transform;
transform: translateY(-4px);
transition:
transform
600ms
cubic-bezier(.3, .7, .4, 1);


@media (min-width: 768px) {
.button-82-front {
font-size: 1.25rem;
padding: 12px 42px;
}
}
`;
