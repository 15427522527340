import styled from "styled-components";
import AUP from "../../assets/sobrenos.png"

export const TitleBarContainer = styled.div`
width: 100%;
height: 5vw;
min-height: 15px;
display: flex;
justify-content: center;
align-items: center;
background-color:#32cd32 ;
color:white;
`;

export const TitleName = styled.span`
width: 90%;
margin: auto;
font-size: 2vw;
font-weight: 900;
text-align: start;
padding-left: 2vw;
font-family: 'Anton', sans-serif;
`;

export const Container =styled.div`
width: 100%;
height: auto;
padding-bottom: 5vw;
background: rgb(244,98,222);
background: linear-gradient(180deg, rgba(244,98,222,1) 16%, rgba(102,175,249,1) 51%, rgba(255,255,255,1) 100%);
color: white;
font-family: 'Anton', sans-serif;
`;

export const AUContainer = styled.div`
width: 90%;
height: 100%;
padding-top: 4vh;
margin: 0 auto;
display: flex;
flex-direction: row;

@media (max-width:768px) {
flex-direction: column-reverse;
justify-content: start;
}
`;

export const LContainerImg = styled.div`
width: 50%;
height: 25vw;
background-image: url(${AUP});
background-repeat: no-repeat;
background-position: center;
background-size: cover;
border-radius: 20px;
box-shadow: rgba(240, 46, 170, 0.4) -5px 5px, rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px, rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px;
margin-top: 2.5vw;

@media (max-width:768px) {
width: 80%;
height: 50vw;
margin: 2.5vw auto;
}
`;

export const RContainerTxt = styled.div`
width: 50%;
height: 100%;
font-size: 1.5vw;

@media (max-width:768px) {
width: 100%;
height: auto;
font-size: 4vw;
}
`;

export const TextContent = styled.div`
width: 80%;
height: auto;
margin: 2vw auto;
text-align: justify;
`;

