import { AUContainer, Container, LContainerImg, RContainerTxt, TextContent, TitleBarContainer, TitleName } from "./styles";

function Team (){
    return(
        <div style ={{width:"100%", height:"100%"}}>
            <TitleBarContainer>
                <TitleName>SOBRE NÓS</TitleName>
            </TitleBarContainer>
            <Container>
            <AUContainer>
                <LContainerImg></LContainerImg>
                <RContainerTxt>
                    <TextContent>
                        O tutela F.C é um time de Futebol Society e Futsal composto por Advogadas, Estagiárias e Bacharéis do curso de Direito.<br/>
                        Fundado em 2016, acumula ao longo desses anos uma extensa lista de títulos coletivos e individuais, se tornando o maior time feminino da história dos campeonatos da advocacia maranhense.<br/>
                        O clube é TETRACAMPEÃO do Campeonato de Futebol Society da Advocacia. E TRICAMPEÃO do Campeonato de Futsal da Advocacia.<br/>
                        Diante da relevância, o Tutela também  recebeu convites para campeonatos externos. Participando campeonatos de beach soccer, travinha e outros campeonatos societys e de futsal.
                    </TextContent>
                </RContainerTxt>
            </AUContainer>
        </Container>
        </div>
    )
}

export default Team;