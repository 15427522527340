import styled from "styled-components";

export const PageContainer = styled.div`
width: 100%;
height: auto;
padding-bottom: 20px;
background: rgb(244,98,222);
background: linear-gradient(180deg, rgba(244,98,222,1) 16%, rgba(102,175,249,1) 51%, rgba(255,255,255,1) 100%);
color: white;
font-family: 'Anton', sans-serif;
`;

export const TitleBarContainer = styled.div`
width: 100%;
height: 5vw;
min-height: 15px;
display: flex;
justify-content: center;
align-items: center;
background-color:white ;
color:gold;
border-top: 1px solid black;
`;

export const TitleName = styled.span`
font-size: 2vw;
font-weight: 900;

@media (max-width:768px) {
font-size: 4vw;
}
`;


export const SlideContainer = styled.div`
width: 100%;
height: 100%;
background-color: white;
border-radius: 30px;
margin: 0 auto;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;


@media (max-width:768px){
background-color: transparent;
}
`;

export const SliderImgContainer = styled.div`
width: 90%;
height: 60%;
border-radius: 20px;
`;

export const SliderTxtContainer = styled.div`
width: 85%;
height: auto;
color: black;
text-align: center;
font-size: 2vw;
margin-top: 10px;

@media (max-width:768px){
color: white;
font-size: 5vw;
}
`;

