import { Container, TitleBarContainer, TitleName, SocietyFutsalTitle, TextContent, TextSpace, Text, TextContent2, TextContainer, Image1, Image2} from "./styles";
import TS2016 from "../../assets/societyTitles/societyTitulo2016.png";
import TS2017 from "../../assets/societyTitles/societyTitulo2017.png";
import TS2018 from "../../assets/societyTitles/societyTitulo2018.png";
import TS2019 from "../../assets/societyTitles/societyTitulo2019.png";
import TS2020 from "../../assets/societyTitles/societyTitulo2020.png";
import TS2021 from "../../assets/societyTitles/societyTitulo2021.png";
import TS2022 from "../../assets/societyTitles/societyTitulo2022.png";
import TF2017 from "../../assets/futsalTitles/FutsalTitulo2017.png";
import TF2021 from "../../assets/futsalTitles/FutsalTitulo2021.png";
import TF2022 from "../../assets/futsalTitles/FutsalTitulo2022.png";
import TF2023 from "../../assets/futsalTitles/futsalTitulo2023.png";

const SocietyTitles = [
    {text1:"Em 2016, ano de sua primeira formação, o Tutela F.C se consagrou campeão do campeonato de society da OAB-VII, de forma invicta, tendo também não só a melhor defesa, com a goleira Laina Suzart, como também a artilheira do campeonato Janaína Macedo, chegando a excelente marca de 08 gols em 05 jogos. Com essas incríveis marcas, o Tutela trouxe um novo ar para o campeonato da advocacia maranhense na modalidade feminina, mostrando toda habilidade e categoria das advogadas no esporte que até então predominante masculino.",
     text2:"O Tutela F.C, em 2017, leva para sua galeria mais um título, novamente de forma invicta, de BiCampeão do Campeonato Society da OAB/MA. Assim, o Tutela mostrou que veio para fazer história, mostrando toda sua força dentro das quatro linhas, apresentando um belo futebol, e mantendo o título de melhor defesa, dessa vez com a goleira Letícia Silva, e artilheira do campeonato, pelo segundo ano consecutivo, Janaína Macedo.",
     Img1:TS2016,Img2:TS2017
    },
    {text1:"Em 2018, pela primeira vez sentimos o gosto da derrota durante a fase de grupos. Porém, na final do campeonato tivemos a oportunidade de enfrentar novamente a forte equipe da Facial F.C e devolvemos o placar de 3x1 e mais uma vez nos consagramos campeãs. Esse foi o ano da estreia da nossa nova comissão Técnica, sob o comando de Simone Campos. ",
     text2:"Em 2019, o campeonato da Advocacia ganhou novos ares com maior participação de times femininos, aumento de nível, e consequente, aumento da competitividade. Mesmo com essas mudanças, o Tutela F.C conseguiu se manter na ponta em toda competição, fazendo jogos incríveis e chegando a final, de forma invicta e com uma campanha invejável. Contudo, todas essas façanhas não impediram que o Tutela F.C amargasse seu primeiro vice campeonato. Em uma final super disputada contra a seleção da UFMA, onde em tempo normal o placar terminou em 1 x 1, o jogo foi definido nos pênaltis e o Tutela levou seu primeiro vice-campeonato. Mesmo com a derrota o Tutela ainda levou para casa o prêmio de artilheira da competição, com a atleta Nhauany Rocha, e melhor defesa, com Letícia Silva.",
     Img1:TS2018,Img2:TS2019
    },
    {text1:"Em 2020, nossa equipe, o Tutela FC, retornou ao lugar mais alto do pódio após termos ficado em segundo lugar no ano anterior, quando fomos derrotados pela equipe da UFMA. A reconquista veio com uma vitória modesta de 1x0, mas foi suficiente para confirmar nossa superioridade, e assim conquistamos nosso quarto título consecutivo (2016, 2017, 2018 e 2020). Um outro feito notável foi a conquista da artilharia por nossa jogadora, Nhauany Baima, que encerrou o campeonato com oito gols em seu nome.",
     text2:"Em 2021, com a constante evolução da competitividade do campeonato, enfrentamos muita dificuldade até alcançarmos a grande final contra a forte equipe da Apcef. Porém, lamentavelmente mais uma vez ficamos com o segundo lugar da competição após o placar de 1x0 no tempor normal. No critério de Melhor Defesa e Melhor Disciplinas também finalizamos o campeonato na segunda colocação.",
     Img1:TS2020,Img2:TS2021
    },
    {text1:"2022 mais uma vez deixamos o título escapar. Dessa vez para a equipe da UFMA, novamente por um placar amargo de 1x0, após a cobrança de um pênalti ainda no 1º tempo de jogo, quando o Tutela F.C teve uma atleta expulsa e assim permaneceu até o final do tempo normal. A equipe terminou o campeonato com a segunda melhor defesa do campeonato.",
     Img1:TS2022
    }
];

const FutsalTitles = [
    {text1:"Aqueles que pensaram que nosso domínio se limitava apenas ao campo, estavam enganados. Após conquistar o título de campeãs da temporada de 2016 no campeonato Society, derrotando o Prime com um impressionante placar de 6x0, agora foi a vez dos amantes do futsal testemunharem o nosso desempenho excepcional na quadra. Ao longo de dois jogos, marcamos doze gols e não sofremos nenhum, o que é mais do que suficiente para nos consagrar como as melhores no futsal em 2017.",
     text2:"Apesar de estarmos à beira de ficar de fora da decisão, conseguimos superar todos os desafios e conquistamos nosso segundo título no Futsal em 2021. A jornada não foi fácil, mas nossa determinação, experiência e foco foram fundamentais para que, mais uma vez, entrássemos em quadra como um time unido, prontos para superar qualquer obstáculo. E essa união prevaleceu até os últimos segundos da partida, quando conseguimos um empate e levamos a disputa para os pênaltis e com grandes defesas da nossa goleira, Leticia, nos sagramos Campeãs!",
     Img1:TF2017,Img2:TF2021
    },
    {text1:"Em 2022, mantivemos nossa hegemonia no campeonato de futsal da OAB-MA, conquistando assim o terceiro título da competição. Essa vitória confirmou nossa supremacia, pois, analisando as estatísticas, fica claro que o nosso desempenho foi superior à das adversárias. Marcamos trinta e cinco gols, uma diferença de dezenove gols em relação à segunda colocada, e sofremos apenas um gol em sete jogos.",
     text2:"Na temporada de 2023, sofremos pela primeira vez uma derrota em finais de Campeonato no Futsal da OAB. Após realizar mais um campeonato impecável, invictas, fomos surpreendidas com uma derrota de 2x1 para a fortíssima equipe da UFMA. Mas a nossa excelente campanha nos rendeu prêmios de Melhor Jogadora, Gol Mais Bonito do Campeonato e Jogadora Revelação, com Janaina Macedo duas vezes e Ana Paula Abreu.",
     Img2:TF2023,Img1:TF2022
    },
];

function History () {
    return(
        <Container>
            <TitleBarContainer>
                <TitleName>TÍTULOS</TitleName>
            </TitleBarContainer>          
            <SocietyFutsalTitle>Society</SocietyFutsalTitle>
            {SocietyTitles.map((societyTitle, index) => (
                <TextContainer key={index}>
                    <TextContent>
                    <Image1 style={{ backgroundImage: `URL(${societyTitle.Img1})` }} />
                    <TextSpace>
                        <Text>
                        {societyTitle.text1}
                        </Text>
                    </TextSpace>
                    </TextContent>
                    {societyTitle.text2 && societyTitle.Img2 && (
                    <TextContent2>
                        <TextSpace>
                        <Text>
                            {societyTitle.text2}
                        </Text>
                        </TextSpace>
                        <Image2 style={{ backgroundImage: `URL(${societyTitle.Img2})` }} />
                    </TextContent2>
                    )}
                </TextContainer>
            ))}
            
            <SocietyFutsalTitle>Futsal</SocietyFutsalTitle>
            {FutsalTitles.map((FutsalTitle, index) => (
                <TextContainer key={index}>
                    <TextContent>
                    <Image1 style={{ backgroundImage: `URL(${FutsalTitle.Img1})` }} />
                    <TextSpace>
                        <Text>
                        {FutsalTitle.text1}
                        </Text>
                    </TextSpace>
                    </TextContent>
                    {FutsalTitle.text2 && FutsalTitle.Img2 && (
                    <TextContent2>
                        <TextSpace>
                        <Text>
                            {FutsalTitle.text2}
                        </Text>
                        </TextSpace>
                        <Image2 style={{ backgroundImage: `URL(${FutsalTitle.Img2})` }} />
                    </TextContent2>
                    )}
                </TextContainer>
            ))}
        </Container>
    )
}

export default History;