import styled from "styled-components";

export const Container = styled.div`
width: 100%;
height: 9vw;
background-color: black;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
font-family: 'Anton', sans-serif;

@media (max-width:768px) {
flex-direction: column-reverse;
height: auto;
}
`;

export const LContainer = styled.div`
width: 30vw;
height: 90%;
display: flex;
justify-content: center;
align-items: center;

@media (max-width:768px) {
width: 100%;
height: auto;
}
`;

export const LetterLContent = styled.div`
font-size: 1.5vw;
font-weight: 700;
color: hsl(308.426, 100%, 54%, 1);
display: flex;
justify-content: center;
padding-top: 20px;

@media (max-width:768px) {
font-size: 4vw;
}
`;

export const MContainer = styled.div`
width: 30vw;
height: 90%;
box-shadow: 6px 0 4px -4px #999, -6px 0 4px -4px #999; 
-moz-box-shadow: 6px 0 4px -4px #999, -6px 0 4px -4px #999; 
-webkit-box-shadow: 6px 0 4px -4px #999, -6px 0 4px -4px #999;
display: flex;
justify-content: center;
align-items: center;
background-color: black;

@media (max-width:768px) {
width: 90%;
height: auto;
box-shadow: none;
-moz-box-shadow: none;
-webkit-box-shadow:none;
padding-top: 10px;
}
`;

export const Logo = styled.img`
height: 70%;
object-fit: contain;

@media (max-width:768px) {
width: 25%;
}
`;

export const RContainer = styled.div`
width: 35vw;
height: 90%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;

@media (max-width:768px) {
width: 70%;
height: auto;
padding-top: 10px;
border-bottom: 1px solid gray;
}
`;

export const RTxtContentUp = styled.div`
width: 40%;
height: 50%;
font-size: 1.2vw;
display: flex;
color: #007fff;
text-align: start;
flex-direction: column;

@media (max-width:768px) {
width: 100%;
font-size: 3.5vw;
margin: 10px;
justify-content: center;
align-items: center;
padding-bottom: 20px;
}
`;


export const RTxtContentDown = styled.div`
width: 40%;
height: 50%;
font-size: 1.2vw;
color: #00ff00;
text-align: start;
display: flex;
flex-direction: column;

@media (max-width:768px) {
width: 80vw;
font-size: 3.5vw;
margin: 10px;
justify-content: center;
align-items: center;
flex-direction: column;
padding-bottom: 20px;
}
`;

export const SocialMedia = styled.div`
height: auto;
display: flex;
justify-content:start;
cursor: pointer;
transition: all .2s ease-in-out;

&:hover {
transform: scale(1.1); 
}

@media (max-width:768px) {
justify-content: center;
}
`;