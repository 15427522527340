import { Arrow, Container, InvisibleContainer, MenuItemB, Span1MIB, Span2MIB, Span3MIB } from "./styles";
import { useContext, useEffect, useState,useRef} from 'react';
import { menuContext } from "../../contexts/menuUser";
import { useNavigate } from "react-router-dom";

const items = [
    {name:"Início", link:"/"},
    {name:"Time", link:"/Time"},
    {name:"Títulos", link:"/Historia"},
    {name:"Atletas", link:"/Jogadoras"},
    {name:"Patrocinadores", link:"/Patrocinadores"}
];


function DDM(){
    const {openMenu, setOpenMenu } = useContext(menuContext);
    const containerRef = useRef<HTMLDivElement>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setIsMenuOpen(openMenu);
      }, [openMenu]);

      useEffect(() => {
        const closeMenuOnOutsideClick = (event: MouseEvent) => {
          if (isMenuOpen && containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setOpenMenu(false);
          }
        };
    
        document.addEventListener('mousedown', closeMenuOnOutsideClick);
    
        return () => {
          document.removeEventListener('mousedown', closeMenuOnOutsideClick);
        };
      }, [isMenuOpen, setOpenMenu]);

    return (
        <InvisibleContainer openMenu = {openMenu} >
            <Container openMenu = {openMenu} ref={containerRef}>
                <Arrow>
                </Arrow>
                {items.map((item, a)=> (
                    <MenuItemB key={null} onClick={()=> navigate(item.link)}>
                        <Span1MIB></Span1MIB>
                        <Span2MIB></Span2MIB>
                        <Span3MIB>
                            {item.name}
                        </Span3MIB>
                    </MenuItemB>
                ))}
            </Container>
        </InvisibleContainer>
    )
}

export default DDM;