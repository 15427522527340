import styled from "styled-components";

export const Container =  styled.div`
width: 100%;
height: 100%;
background: rgb(244,98,222);
background: linear-gradient(180deg, rgba(244,98,222,1) 16%, rgba(102,175,249,1) 51%, rgba(255,255,255,1) 100%);
color: white;
font-family: 'Anton', sans-serif;
`;

export const TitleBarContainer = styled.div`
width: 100%;
height: 5vw;
min-height: 15px;
display: flex;
justify-content: center;
align-items: center;
background-color:#4169e1 ;
color:white;

@media (max-width:768px) {
height: 10vw;
}
`;

export const TitleName = styled.span`
font-size: 2vw;
font-weight: 900;

@media (max-width:768px) {
font-size: 4vw;
}
`;

export const TextContainer = styled.div`
width:80%;
height: auto;
margin: 0 auto;
`;

export const SocietyFutsalTitle = styled.div`
width: 30%;
height: 2vw;
font-size: 2vw;
font-weight: 800;
margin-top: 2vw;
margin-bottom: 2vw;
display: flex;
justify-content: center;
text-decoration: underline;

@media (max-width:768px) {
font-size: 4vw;
padding-bottom: 2vw;
}
`;

export const TextContent = styled.div`
width: 100%;
font-size: 1.5vw;
display: flex;
flex-direction: row;
margin-top: 4vw;
margin-bottom: 4vw;

@media (max-width:768px) {
font-size: 3vw;
flex-direction: column;
}
`;

export const TextContent2 = styled.div`
width: 100%;
font-size: 1.5vw;
display: flex;
flex-direction: row;
margin-top: 4vw;
margin-bottom: 4vw;

@media (max-width:768px) {
font-size: 3vw;
flex-direction: column-reverse;
}
`;

export const Image1 = styled.div`
width: 50%;
height: 25vw;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
border: none;
border-radius: 30px;
box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

@media (max-width:768px) {
width: 90%;
margin: 0 auto;
height: 50vw;
}
`;

export const Image2 = styled.div`
width: 50%;
height: 25vw;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
border-radius: 30px;
box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

@media (max-width:768px) {
width: 90%;
margin: 0 auto;
height: 50vw;
}
`;

export const TextSpace = styled.div`
width:50%;
height: auto;
text-align: justify;
@media (max-width:768px) {
width: 100%;
margin-top: 4vw;
}
`;

export const Text = styled.div`
width:90%;
margin:0 auto;
-webkit-text-stroke-width: 1.5px;
-webkit-text-stroke-color: black;

@media(max-width: 768px){
-webkit-text-stroke-width: 0px;
color: black;
}
`;