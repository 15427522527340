import { Container, DDM, LContainer, Logo, LogoContainer, NT, NavigateButton1, NavigateButton2, NavigateButton3, NavigateButton4, RContainer, Span1DDM, Span2DDM, Span3DDM } from "./styles"
import { useContext, useEffect } from 'react';
import { menuContext } from "../../contexts/menuUser";
import L from "../../assets/tutelogo.png"
import {HiOutlineMenu} from "react-icons/hi"
import { useNavigate} from "react-router-dom";


function Header(){
    const {openMenu, setOpenMenu} = useContext(menuContext);
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [navigate]);

    const HandleButtonClick = () => {
        setOpenMenu(!openMenu);
    }

    return (
        <Container>
            <LContainer>
                <LogoContainer>
                    <Logo alt="" src={L} onClick={()=> navigate("/")}/>
                </LogoContainer>
            </LContainer>
            <RContainer>
                <NavigateButton1 onClick={() => navigate("/Time")}>
                    <NT>Time</NT>
                </NavigateButton1>
                <NavigateButton2 onClick={() => navigate("/Historia")}>
                    <NT>Títulos</NT>
                </NavigateButton2 >
                <NavigateButton3 onClick={() => navigate("/Jogadoras")}>
                    <NT>Atletas</NT>
                </NavigateButton3>
                <NavigateButton4 onClick={() => navigate("/Patrocinadores")}>
                    <NT>Patrocinadores</NT>
                </NavigateButton4>
                <DDM onClick={HandleButtonClick }>
                    <Span1DDM></Span1DDM>
                    <Span2DDM></Span2DDM>
                    <Span3DDM>
                        <HiOutlineMenu />
                    </Span3DDM>
                </DDM>
            </RContainer>
        </Container>
    )
 }

export default Header;