import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`
from{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    
}
to{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}
`;

const fadeInAnimationReverse = keyframes`
from{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}
to{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    
}
`;

export const InvisibleContainer =styled.div<{openMenu : boolean}>`
width: 98%;
position: absolute;
display: ${({openMenu})=> openMenu? 'flex' : 'none'};
justify-content: end;

`;

export const Container = styled.div<{openMenu : boolean}>`
width: 300px;
height: auto;
box-sizing: border-box;
padding-left: 10px;
padding-right: 10px;
padding-top: 20px;
padding-bottom: 30px;
display:flex;
align-items: center;
flex-direction: column;
overflow-y: auto;
background-color: white;
border-radius: 20px;
font-family: 'Anton', sans-serif;
margin-top: 10px;
margin-left: 0;
background-color: rgba(50,205,50,1);
box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
animation: ${fadeInAnimation} 0.2s ease;
position: static;
z-index: 50;
`;

export const Arrow = styled.div`
content:"";
position: absolute;
top: 0;
right: 40px;
height: 20px;
width: 20px;
background-color: rgba(50,205,50,1);
rotate: 45deg;
`;

export const MenuItemB = styled.button`
display: none;
justify-content: center;
align-items: center;
width: 90%;
position: relative;
border: none;
background: transparent;
padding: 0;
cursor: pointer;
outline-offset: 4px;
transition: filter 250ms;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
margin-top: 15px;
text-align:left;

&:hover {
filter: brightness(110%);
-webkit-filter: brightness(110%);
transition:
transform
250ms
cubic-bezier(.3, .7, .4, 1.5);
}

:active{
transform: translateY(-2px);
transition: transform 80ms;
}

@media (max-width:768px) {
display: flex;
}
`;

export const Span1MIB = styled.span`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
border-radius: 12px;
background: hsl(0deg 0% 0% / 0.25);
will-change: transform;
transform: translateY(2px);
transition:
transform
600ms
cubic-bezier(.3, .7, .4, 1);
`;

export const Span2MIB = styled.span`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
border-radius: 12px;
background: linear-gradient(
to left,
hsl(308.426 100% 16%) 0%,
hsl(308.426 100% 32%) 8%,
hsl(308.426 100% 32%) 90%,
hsl(308.426 100% 16%) 100%
  );
`;

export const Span3MIB = styled.span`
display: block;
position: relative;
width: 100%;
padding: 3px 15px;
border-radius: 12px;
font-size: 2rem;
color: white;
background: hsl(308.426, 100%, 54%, 1);
will-change: transform;
transform: translateY(-4px);
transition:
transform
600ms
cubic-bezier(.3, .7, .4, 1);


@media (min-width: 768px) {
.button-82-front {
font-size: 1.25rem;
padding: 12px 42px;
}
}
`;
