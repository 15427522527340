import { useState } from "react";
import { AthleteContainer, AthleteName, AthleteNameContent, AthleteNumberContent, PageContainer, PictureAthlete, SearchTxtArea, TeamContainer, TitleBarContainer, TitleName } from "./styles";
import renata from "../../assets/Athletes/renata.png";
import patriciaB from "../../assets/Athletes/patriciabonfim.png";
import lais from "../../assets/Athletes/lais.png";
import elem from "../../assets/Athletes/elem.png";
import jana from "../../assets/Athletes/janna.png";
import nany from "../../assets/Athletes/nany.png";
import leticia from "../../assets/Athletes/leticia.png";
import jordana from "../../assets/Athletes/jordana.png";
import tamara from "../../assets/Athletes/tamara.png";
import lila from "../../assets/Athletes/lila.png";
import nathalia from "../../assets/Athletes/nathalia.png";
import stefany from "../../assets/Athletes/stefany.png";
import dinara from "../../assets/Athletes/dinara.png";
import rafaela from "../../assets/Athletes/rafaela.png";
import thais from "../../assets/Athletes/thais.png";
import kessia from "../../assets/Athletes/kessia.png"
import laina from "../../assets/Athletes/Laina.png"
import Vania from "../../assets/Athletes/VaniaMartins.png"
import Thalita from "../../assets/Athletes/ThalitaCampos.png"
import AdrielleR from "../../assets/Athletes/AdrielleRufino.png"
import IsabelaA from "../../assets/Athletes/IsabelaAparecida.png"
import Victoria from "../../assets/Athletes/Victoria.png"
import AnaB from "../../assets/Athletes/AnaB.png"
import Monnara from "../../assets/Athletes/Monnara.png"
import OliviaCastro from "../../assets/Athletes/OliviaCastro.png"




const Atletas = [
    {nome:"Patrícia Bonfim", foto:patriciaB, posicao:"01"},
    {nome:"Rafaella Lima", foto:rafaela, posicao:"02"},
    {nome:"Victoria Kethleen", foto:Victoria, posicao:"03"},
    {nome:"Laís Rodrigues", foto:lais, posicao:"04"},
    {nome:"Elem Lima", foto:elem, posicao:"05"},
    {nome:"Monnara Porfiro", foto:Monnara, posicao:"06"},
    {nome:"Janaina Macedo", foto:jana, posicao:"07"},
    {nome:"Thais Nadyne", foto:thais, posicao:"08"},
    {nome:"Laina Suzart", foto:laina, posicao:"09"},
    {nome:"Nhauany Rocha", foto:nany, posicao:"11"},
    {nome:"Letícia Silva", foto:leticia, posicao:"12"},
    {nome:"Jordana Gonçalves", foto:jordana, posicao:"14"},
    {nome:"Tamara Dallagnol", foto:tamara, posicao:"15"},
    {nome:"Renata Cristina", foto:renata, posicao:"17"},
    {nome:"Isabela Aparecida", foto:IsabelaA, posicao:"18"},
    {nome:"Adrielle Rufino", foto:AdrielleR, posicao:"19"},
    {nome:"Lila Borges", foto:lila, posicao:"20"},
    {nome:"Vânia Martins", foto:Vania, posicao:"21"},
    {nome:"Nathalia Silva", foto:nathalia, posicao:"24"},
    {nome:"Stefany Rodrigues", foto:stefany, posicao:"31"},
    {nome:"Kessia Brito", foto:kessia, posicao:"77"},
    {nome:"Dinara Oliveira", foto:dinara, posicao:"88"},
    {nome:"Ana Beatriz", foto:AnaB, posicao:""},
    {nome:"Thalita Campos", foto:Thalita, posicao:""},
    {nome:"Olivia Castro", foto:OliviaCastro, posicao:""},
];

function Lineup(){
    const [search, setSearch] = useState("");

    const FilteredAthletes = 
    Atletas.filter((Atleta)=> Atleta.nome.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()));

    return (
        <PageContainer>
            <TitleBarContainer>
                <TitleName>ATLETAS</TitleName>
                <SearchTxtArea type="text" value={search} onChange={(e)=> setSearch(e.target.value)}/>
            </TitleBarContainer>
            <TeamContainer>
                {FilteredAthletes.map((Atleta)=> (
                    <AthleteContainer key={null}>
                        <PictureAthlete style={{backgroundImage:`URL(${Atleta.foto})`}}/>
                        <AthleteName>
                            <AthleteNameContent>
                                {Atleta.nome}
                            </AthleteNameContent>
                            <AthleteNumberContent>
                                {Atleta.posicao}
                            </AthleteNumberContent>
                        </AthleteName>
                    </AthleteContainer>
                ))}
            </TeamContainer>
        </PageContainer>
    )
}

export default Lineup;