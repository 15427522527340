import { Container, LContainer, LetterLContent, Logo, MContainer, RContainer, RTxtContentDown, RTxtContentUp, SocialMedia } from "./styles";
import L from "../../assets/tutelogo2.png"
import{ AiOutlineInstagram,AiOutlineMail} from "react-icons/ai"
import {FaSquareXTwitter} from "react-icons/fa6"
import {BiSolidContact} from "react-icons/bi"

const Insta = () => {
    window.location.href = "https://www.instagram.com/tutela_fc/";
}

const Twitter = () => {
    window.location.href = "https://x.com/tutela_fc?s=11";
}

const Email = 'tutelafc@gmail.com';

const copiarEmailParaAreaDeTransferencia = async () => {
    try {
        await navigator.clipboard.writeText(Email);
        alert('Email copiado!');
    } catch (error) {
        console.error('Falha ao copiar para a área de transferência: ', error);
    }
};

const Numero = '98 9 99714038';

const copiarNumeroParaAreaDeTransferencia = async () => {
    try {
        await navigator.clipboard.writeText(Numero);
        alert('Numero copiado!');
    } catch (error) {
        console.error('Falha ao copiar para a área de transferência: ', error);
    }
};

function Footer(){ 
    return (
        <Container>
            <LContainer>
                <LetterLContent>T.F.C - TUTELA FUTEBOL CLUBE</LetterLContent>
            </LContainer>
            <MContainer>
                <Logo alt="" src={L} />
            </MContainer>
            <RContainer>
                <RTxtContentUp>
                    Midias Sociais:<br/>
                    <SocialMedia onClick={Insta}><AiOutlineInstagram style={{fontSize:"2.1vw",alignItems: "center"}}/> @tutela_fc</SocialMedia>
                    <SocialMedia onClick={Twitter}><FaSquareXTwitter style={{fontSize:"1.8vw",alignItems: "center", paddingLeft:"2px"}}/> @tutela_fc </SocialMedia>
                </RTxtContentUp>
                <RTxtContentDown>
                    Contato:
                    <SocialMedia onClick={copiarEmailParaAreaDeTransferencia}><AiOutlineMail style={{fontSize:"2vw",alignItems: "center"}}/>tutelafc@gmail.com</SocialMedia>
                    <SocialMedia onClick={copiarNumeroParaAreaDeTransferencia}><BiSolidContact style={{fontSize:"1.8vw",alignItems: "center", paddingLeft:"2px"}}/>98 9 99714038</SocialMedia>
                </RTxtContentDown>
            </RContainer>
        </Container>
    )
}

export default Footer;