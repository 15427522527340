import {PageContainer, SlideContainer, SliderImgContainer, SliderTxtContainer, TitleBarContainer, TitleName } from "./styles";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import styled from "styled-components";

import oab from "../../assets/sponsors/oab.png";
import caama from "../../assets/sponsors/CAAMA.png";
import onabet from "../../assets/sponsors/onabet.png";
import MendesELobato from "../../assets/sponsors/MendesELobato-1.png"
import OliviaCastro from "../../assets/sponsors/Olivia.png"
import Magnolia from "../../assets/sponsors/Magnolia-1.png"

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import { BsArrowRightCircleFill } from "react-icons/bs"

const CarrouselContainer = styled.div`
width: 100%;
height: 80vh;
margin: 100px auto;
display: flex;
justify-content: center;
align-items: center;


.swiper_container {
  height: 52rem;
  padding: 2rem 0;
  position: relative;
}

.swiper-slide {
  width: 37rem;
  height: 42rem;
  position: relative;
}

@media (max-width: 500px) {
  .swiper_container {
    height: 47rem;
    display: none;
  }
  .swiper-slide {
    width: 25rem !important;
    height: 36rem !important;
  }
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-height: 800px) {
  .slider-controler .swiper-button-next {
    display: none;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-height: 800px) {
  .slider-controler .swiper-button-prev {
    display: none;
  }
  .swiper-pagination {
  display: none;
}
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: white;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.slider-controler .slider-arrow {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: '';
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}
`;

const slides = [{nome:"OAB/MA", img: oab},{nome:"CAAMA", img: caama},{nome:"ONABET", img: onabet},{nome:"Mendes e Lobato", img: MendesELobato},{nome:"Olivia Castro", img: OliviaCastro},{nome:"Magnolia", img: Magnolia}];

function Sponsors(){
    return(
        <PageContainer>
            <TitleBarContainer>
                <TitleName>PATROCINADORES</TitleName>
            </TitleBarContainer>
            <CarrouselContainer>
                <Swiper
                centeredSlides = {true}
                effect={"coverflow"}
                grabCursor = {true}
                slidesPerView={"auto"}
                coverflowEffect={{
                    rotate:50,
                    stretch: 0,
                    depth:100,
                    modifier:2.5,
                    slideShadows: true
                }}
                pagination={{ el: '.swiper-pagination', clickable: true }}
                navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="swiper_container"
                >
                    <div>
                        {slides.map((slide, i)=> 
                            <SwiperSlide key = {i}>
                                <SlideContainer>
                                  <SliderImgContainer style={{ backgroundImage:`url(${slide.img})`,backgroundRepeat:"no-repeat",backgroundPosition: "center" ,backgroundSize:"cover"}}/>
                                  <SliderTxtContainer>{slide.nome}</SliderTxtContainer>
                                </SlideContainer>
                            </SwiperSlide>
                            )
                        }
                    </div>
                    <div className="slider-controler">
                      <div className="swiper-button-prev slider-arrow">
                          <BsArrowRightCircleFill style={{rotate:"180deg", color:"#fe019a"}}/>
                      </div>
                      <div className="swiper-button-next slider-arrow">
                          <BsArrowRightCircleFill style={{color:"#fe019a"}} />
                      </div>
                      <div className="swiper-pagination"></div>
                    </div>

                </Swiper>
            </CarrouselContainer>
        </PageContainer>
    )
}

export default Sponsors;