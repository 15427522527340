import styled from "styled-components";

export const PageContainer = styled.div`
width: 100%;
height: auto;
min-height: 100vh;
background: rgb(244,98,222);
background: linear-gradient(180deg, rgba(244,98,222,1) 16%, rgba(102,175,249,1) 51%, rgba(255,255,255,1) 100%);
color: white;
font-family: 'Anton', sans-serif;
`;

export const TitleBarContainer = styled.div`
width: 100%;
height: 5vw;
min-height: 15px;
display: flex;
justify-content: space-around;
align-items: center;
background-color:gold ;
color:#4169e1;

@media (max-width:768px) {
height: 10vw;
}
`;

export const TitleName = styled.span`
font-size: 2vw;
font-weight: 900;

@media (max-width:768px) {
font-size: 4vw;
}
`;

export const TeamContainer = styled.div`
width: 90%;
height: 90%;
margin: 0 auto;
padding-top: 20px;
padding-bottom: 20px;
display: flex;
flex-wrap: wrap;
justify-content: center;
`;

export const AthleteContainer = styled.div`
width: 140px;
height: 170px;
min-height: 10vw;
border-radius: 20px;
background-color: white;
padding: 10px;
margin: 20px;
box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;

@media (max-width:768px) {
width: 220px;
height: 270px;
}
`;

export const PictureAthlete = styled.div`
width: 100px;
height: 100px;
margin: 5px auto;
background-color: black;
border-radius: 50%;
background-repeat: no-repeat;
background-position: center;
background-size: cover;

@media (max-width:768px) {
width: 90%;
height: 70%;
}
`;

export const AthleteName = styled.div`
width: 90%;
height: auto;
text-overflow: clip;
margin: 10px auto;
color: black;
text-align: center;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const AthleteNameContent = styled.span`
width: 100%;
height: 100%;
display: inline;
`;

export const AthleteNumberContent = styled.span`
width: 100%;
height: 100%;
display: inline;
`;

export const SearchTxtArea = styled.input`
width: 20vw;
height: 50%;
border-radius: 20px;
border: none;
box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
padding-left: 10px;
font-size: 1vw;
font-weight: 600;
font-family: 'Anton', sans-serif;

@media (max-width: 768px) {
width: 40%;
font-size: 3vw;
}
`;